<template>
  <div class="container">
    <el-row :gutter="30">
      <el-col :span="6">
        <div class="container_left">
          <img :src="activity?.picUrl" alt="">
          <div class="container_left_content">
            <div class="container_left_content_title">{{ activity?.name }}</div>
            <div v-if="status=='2'" class="container_left_content_sign">
              <div class="container_left_content_sign_status2">报名中</div>
              <div class="container_left_content_sign_num">已报名<span
                  style="color: #FF9100">{{ activity?.alreaySignUpNum }}</span>/{{ activity?.limitNum }}人
              </div>
            </div>
            <div v-if="status=='3'" class="container_left_content_sign">
              <div class="container_left_content_sign_status3">未开始</div>
              <div class="container_left_content_sign_num">开始时间：{{ activity?.starttime }}</div>
            </div>
            <div v-if="status=='1'" class="container_left_content_sign">
              <div class="container_left_content_sign_status1">进行中</div>
              <div class="container_left_content_sign_num">
                {{ activity?.starttime }} 至 {{ activity?.endtime }}
              </div>
            </div>
            <div v-if="status=='0'" class="container_left_content_sign">
              <div class="container_left_content_sign_status0">已结束</div>
              <div class="container_left_content_sign_num">结束时间：{{ activity?.endtime }}</div>
            </div>
            <div class="container_left_content_tags">
              <div class="container_left_content_tags_actype">{{ activity?.activityType?.name }}</div>
              <div class="container_left_content_tags_actype" v-if="activity?.money=='0'">免费活动</div>
              <div class="container_left_content_tags_actype" v-if="activity?.longType=='1'">长期活动</div>
              <div v-if="activity?.longType=='1'" class="container_left_content_tags_actype">
                可{{ activity?.scoreType == '1' ? '获得' : '消耗' }}{{ activity?.score }}积分
              </div>
            </div>
            <div class="container_left_content_line"></div>
            <div class="container_left_content_community">
              <img src="../../assets/images/activity/community.png" alt="">
              <div class="container_left_content_community_name">{{ activity?.organizer }}</div>
              <div class="container_left_content_community_des">主办方</div>
            </div>
            <div class="container_left_content_user">
              <div class="container_left_content_user_name">{{ activity?.organizerUsername }}</div>
              <img src="../../assets/images/activity/phone.png" alt="">
              <div class="container_left_content_user_phone">{{ activity?.organizerPhone }}</div>
            </div>
            <div class="container_left_content_line"></div>
            <div class="container_left_content_info">
              <img :src="activity?.pubUser?.avatar" alt="">
              <div class="container_left_content_info_user">
                {{ activity?.pubUser?.truename?(activity?.pubUser?.truename):(activity?.pubUser?.username) }}发布于{{ activity?.createTime?.substring(0, 10).replaceAll('-', '.') }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="container_right">
          <div class="container_right_back">
            <div class="container_right_back_left">
              <img src="../../assets/images/activity/address.png" alt="">
              <div class="container_right_back_left_address"> {{ activity?.addressDetail }}</div>
            </div>
            <div class="container_right_back_right">
            </div>
            <el-button v-if="status=='2'" color="#FFC01E" style="color:#000;font-weight: bolder"
                       @click="openSign">我要报名
            </el-button>
            <el-button v-if="status=='2'&&active=='longActivity'" color="#FFC01E"
                       style="color:#000;font-weight: bolder" @click="showClaim=true">认领活动
            </el-button>
            <el-button v-if="status=='1'&&isSign==false" color="#FFC01E" style="color:#000;font-weight: bolder"
                       @click="confirmSignUp(false)">签到
            </el-button>
            <el-button v-if="status=='1'&&isSign==true&&isSignOff==false" color="#FFC01E" style="color:#000;font-weight: bolder"
                       @click="confirmSignUp(true)">签退
            </el-button>
            <el-button v-if="status=='1'&&type==='signOff'" color="#FFC01E" style="color:#000;font-weight: bolder">签退
            </el-button>

            <el-button v-if="status=='0'" color="#FFC01E" style="color:#000;font-weight: bolder"
                       @click="showComment=true">活动评价
            </el-button>
          </div>
          <div class="container_right_content">
            <div class="container_right_content_time">
              <div class="container_right_content_time_activity">
                <img src="../../assets/images/activity/time.png" alt="">
                <div class="container_right_content_time_activity_des">活动时间：{{
                    activity?.starttime
                  }}至{{ activity?.endtime }}
                </div>
              </div>
              <div class="container_right_content_time_activity">
                <img src="../../assets/images/activity/time.png" alt="">
                <div class="container_right_content_time_activity_des">报名截止时间：{{ activity?.signEndTime }}</div>
              </div>
            </div>
            <div class="container_right_content_line"></div>
            <div class="container_right_content_introduce">
              {{ activity?.content }}
            </div>
          </div>
          <!--   评论       -->
          <div class="container_right_comment" v-if="status==='0'">
            <div class="container_right_comment_top">
              <img src="../../assets/images/activity/comment.png" alt="">
              <div class="container_right_comment_top_text">活动评价</div>
            </div>
            <div class="container_right_comment_item" v-for="(item, i) in list" :key="i">
              <div class="container_right_comment_item_line"></div>
              <div class="container_right_comment_item_user">
                <img :src="item?.fromUser?.avatar" alt="">
                <div class="container_right_comment_item_user_name">
                  <div class="container_right_comment_item_user_name_top">
                    {{ item?.fromUser?.truename?(item?.fromUser?.truename):(item?.fromUser?.username) }}
                  </div>
                  <div class="container_right_comment_item_user_name_bottom">
                    {{ item?.fromUser?.vipRange ? (item?.fromUser?.vipRange) : "1" }}星
                  </div>
                </div>
                <div class="container_right_comment_item_user_time">{{ item?.createTime.substring(0, 10) }}</div>
              </div>
              <div class="container_right_comment_item_star">
                <img v-for="(a, i) in item?.starNumList" :key="i" src="../../assets/images/activity/star.png" alt="">
              </div>
              <div class="container_right_comment_item_content">{{ item?.content }}</div>
              <div class="container_right_comment_item_images" v-if="item.picList">
                <img v-for="(pic, i) in item.picList" :key="i" :src="pic.picUrl" alt="">
              </div>
              <div class="container_right_comment_item_bottom">
                <img src="../../assets/images/activity/flower.png" alt="">
                <div class="container_right_comment_item_bottom_des">赠送了 <span style="color: #FF3D00">{{
                    item?.score
                  }}</span> 朵小红花
                </div>
              </div>
            </div>
            <div class="container_right_comment_page">
              <el-pagination background :page-size="query.pageSize"
                             @current-change="currentChange"
                             @prev-click="upPage"
                             @next-click="downPage"
                             layout="prev, pager, next"
                             :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

  <el-dialog
      v-model="showClaim"
      width="60%"
      :before-close="handleCloseClaim"
  >
    <div class="dialogS">
      <div class="dialogSignUp">
        <div class="dialogSignUp_sign">认领活动</div>
        <div class="dialogSignUp_line"></div>
        <div class="dialogSignUp_sign">
          为您的队伍认领该长期活动
        </div>
      </div>
    </div>
    <el-button color="#FFC01E" style="color:#000;font-size:20px;font-weight: bolder;height: 60px;width: 30%"
               @click="submitClaim">确定
    </el-button>
  </el-dialog>

  <el-dialog
      v-model="showSign"
      width="60%"
      :before-close="handleCloseSign"
  >
    <div class="dialog">
      <div class="dialog_user">
        <div class="dialog_user_info">会员信息</div>
        <div class="dialog_user_line"></div>
        <div class="dialog_user_container">
          <div class="dialog_user_container_item1">
            <div class="dialog_user_container_item1_left">
              <div class="dialog_user_container_item1_left_name">姓名</div>
              <el-input v-model="name" disabled/>
            </div>
            <div class="dialog_user_container_item1_right">
              <div class="dialog_user_container_item1_right_name">性别</div>
              <el-input v-model="sex" disabled/>
            </div>
          </div>
          <div class="dialog_user_container_item">
            <div class="dialog_user_container_item_name">所在站点</div>
            <el-input v-model="station" disabled/>
          </div>
          <div class="dialog_user_container_item">
            <div class="dialog_user_container_item_name">服务队</div>
            <el-input v-model="team" disabled/>
          </div>
        </div>
      </div>
      <div class="dialog_user">
        <div class="dialog_user_info" style="color: #FF6200;">报名要求</div>
        <div class="dialog_user_line"></div>
        <div class="dialog_user_container">
          <div class="dialog_user_container_item">
            <div class="dialog_user_container_item_name">工作单位</div>
            <el-input v-model="company" placeholder="请输入"/>
          </div>
<!--          <div class="dialog_user_container_item" v-if="activity?.longType=='1'">
            <div class="dialog_user_container_item_name">活动时间</div>
            <el-select v-model="childId" clearable placeholder="请选择">
              <el-option
                  v-for="item in childActivityList"
                  :key="item.id"
                  :label="item.starttime"
                  :value="item.id"
              />
            </el-select>
          </div>-->
        </div>
      </div>
    </div>
    <el-button color="#FFC01E" style="color:#000;font-weight: bolder;height: 60px;width: 30%" @click="submitSign">立即报名
    </el-button>
  </el-dialog>

  <el-dialog
      v-model="showPayCheck"
      width="60%"
  >

    <div class="paydialog">
      <div class="paydialog_recharge">选择支付方式</div>
      <div class="paydialog_line"></div>
      <div class="paydialog_type">
        <div @click="chooseItem(index)"
             :class="[item.active===true ?'paydialog_type_item--checked' : 'paydialog_type_item']"
             v-for="(item,index) in cartList"
             :key="index"
        >
          <span style="position: absolute;">
            <img src="../../assets/images/wxpay.png" alt="">
          </span>
          <span style="position: absolute;left: 80px">
            {{ item.name }}
          </span>
          <span style="position: absolute;left:330px" v-show="item.active">
            <img src="../../assets/images/checked.png" alt="">
          </span>
        </div>
      </div>
      <el-button color="#FFC01E" style="color:#000;font-size:20px;font-weight: bolder;height: 60px;width: 30%"
                 @click="confirmPay">确定
      </el-button>
    </div>

  </el-dialog>

  <el-dialog
      v-model="payDialogVisible"
      title="支付"
      :before-close="closePayDialog"
  >

    <div class="paydialog">
      <div class="paydialog_recharge">应付金额<span class="paydialog_left_recharge_money">&yen;{{ activity?.money }}</span>
      </div>
      <div class="paydialog_qrcode">
        <qrcode-vue
            :value="qrCodeUrl"
            :size="200"
            level="H"
        />

      </div>
      <div class="paydialog_btn"><span><img
          src="../../assets/images/scan.png"
          alt=""
      ></span><span>打开微信，扫描二维码支付</span></div>
    </div>

  </el-dialog>

  <el-dialog
      v-model="showSignUp"
      width="60%"
      :before-close="handleCloseSignUp"
  >
    <div class="dialogS">
      <div class="dialogSignUp">
        <div class="dialogSignUp_sign">{{ isSign == false ? "签到" : "签退" }}</div>
        <div class="dialogSignUp_line"></div>
        <img src="../../assets/images/activity/success.png" alt="">
        <div class="dialogSignUp_success">{{ isSign == false ? "签到" : "签退" }}成功</div>
        <div class="dialogSignUp_prefix">您参与的活动</div>
        <div class="dialogSignUp_name">{{ activity?.name }}</div>
        <div class="dialogSignUp_line"></div>
        <div class="dialogSignUp_address">
          <div class="dialogSignUp_address_name">{{ isSign == false ? "签到" : "签退" }}地点</div>
          <div class="dialogSignUp_address_des">{{ activity?.addressDetail }}</div>
        </div>
        <div class="dialogSignUp_address">
          <div class="dialogSignUp_address_name">{{ isSign == false ? "签到" : "签退" }}时间</div>
          <div class="dialogSignUp_address_des">{{ data?.createTime }}</div>
        </div>
      </div>
    </div>

  </el-dialog>
  <el-dialog
      v-model="showComment"
      width="60%"
      :before-close="handleCloseComment"
  >

    <div class="dialog">
      <div class="dialog_user">
        <div class="dialog_user_info">活动评价</div>
        <div class="dialog_user_line"></div>
        <div class="dialog_user_star">
          <el-rate
              size="large"
              v-model="star"
              :texts="['差评', '差评', '还行', '还行', '非常好']"
              show-text
          />
        </div>
      </div>
      <div class="dialog_user">
        <div class="dialog_user_info">评价信息</div>
        <div class="dialog_user_line"></div>
        <el-input
            v-model="textarea"
            type="textarea"
            placeholder="请输入"
            resize='none'
            :rows="5"
        />
        <div class="dialog_user_info" style="margin-top: 60px">活动照片</div>
        <div class="dialog_user_line"></div>
        <div class="dialog_user_upload">
          <el-upload
              accept=".jpg,.png,.JPG,.PNG,.jpeg"
              :action="uploadFileUrl"
              :headers="headers"
              :on-error="handleUploadError"
              :before-upload="beforeUpload"
              list-type="picture-card"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccess"
              :file-list="fileList"
          >
            <el-icon>
              <Plus/>
            </el-icon>
          </el-upload>
        </div>
      </div>
      <div class="dialog_user">
        <div class="dialog_user_re">
          <div class="dialog_user_re_info">给活动赠送小红花</div>
          <img src="../../assets/images/activity/flower.png" alt="">
          <div class="dialog_user_re_des">一朵小红花等于1积分</div>
        </div>
        <div class="dialog_user_line"></div>
        <div class="dialog_user_stepper">
          <div class="dialog_user_stepper_del" @click="delNum">
            <img v-if="flower==0" src="../../assets/images/activity/del_ban.png" alt="">
            <img v-if="flower>0" src="../../assets/images/activity/del.png" alt="">
          </div>
          <div class="dialog_user_stepper_num"> {{ flower }}</div>
          <div class="dialog_user_stepper_add" @click="addNum">
            <img v-if="flower<allflower" src="../../assets/images/activity/add.png" alt="">
            <img v-if="flower==allflower" src="../../assets/images/activity/add_ban.png" alt="">
          </div>
        </div>
        <div class="dialog_user_allFlower">当前还有{{ allflower }}朵小红花课赠送</div>
      </div>
    </div>
    <el-button color="#FFC01E" style="color:#000;font-weight: bolder;height: 60px;width: 30%" @click="submitComment">提交
    </el-button>
  </el-dialog>

</template>

<script setup>
import {ElMessage, ElMessageBox} from 'element-plus'
import {ref} from "vue";
import {useRoute} from "vue-router";
import QrcodeVue from 'qrcode.vue'
import {
  addComment,
  checkSign, checkSignOff,
  claim,
  getActivityListNotPage,
  getCommentList,
  getDetail,
  sign,
  signoff,
  signup
} from "@/api/activity";
import {socketUrl, target} from "@/utils/URL";
import {getToken, getUserId} from "@/utils/auth";
import {getUserInfo} from "@/api/user";

const route = useRoute();
const id = route.query.id;
const type = ref('sign')
const status = ref('0')
type.value = route.query.type;
const active = route.query.active;
console.log(type)
console.log(id)

const childActivityList = ref([]);

const currentChange = (val) => {
  query.value.pageNum = val
  onLoad()
}
const upPage = () => {
  query.value.pageNum--;
  onLoad()
}
const downPage = () => {
  query.value.pageNum++;
  onLoad()
}
const total = ref(0);
const list = ref({})
const query = ref({})
query.value = {pageNum: 1, pageSize: 2}

const onLoad = async () => {

  let response = await getCommentList(query.value,{aid:id});
  if (response.rows.length > 0) {
    console.log(response)
    list.value = response.rows
    total.value = response.total
  }
}
onLoad();


const isSign = ref(false);
const getCheckSign = async () => {
  let response = await checkSign(id);
  isSign.value = response.data.isSign
}
getCheckSign();
const isSignOff = ref(false);
const getCheckSignOff = async () => {
  let response = await checkSignOff(id);
  isSignOff.value = response.data.isSignOff
}
getCheckSignOff();
const user = ref({});
const getUser = async () => {
  let response = await getUserInfo();
  user.value = response.data;
  allflower.value = user.value.score
  name.value = user.value.truename?user.value.truename:user.value.username
  sex.value = user.value?.sex === "0" ? '男' : "女"
  station.value = user.value?.vlStation?.name
  team.value = user.value?.vlTeam?.name
}
getUser()

const activity = ref({});
const getAcDetail = async () => {
  let response = await getDetail(id);
  activity.value = response.data
  status.value = response.data.status
}
getAcDetail();


const uploadFileUrl = ref(target + "common/upload")  // 上传的图片服务器地址
const headers = ref({
  Authorizationapp: getToken(),
})

const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    ElMessage.error('上传头像图片大小不能超过 2MB!');
  }
  return isLt2M;
}


const fileList = ref([])
const picUrls = ref([])
const flower = ref(0)
const allflower = ref(6)

const delNum = () => {
  if (flower.value > 0) {
    flower.value--;
  }
}
const addNum = () => {
  if (flower.value < allflower.value) {
    flower.value++;
  }
}
const handleRemove = (uploadFile, uploadFiles) => {
  picUrls.value = []
  for (const e of uploadFiles) {
    picUrls.value.push(e.response.url)
  }
}
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  picUrls.value = []
  for (const e of uploadFiles) {
    picUrls.value.push(e.response.url)
  }
  console.log(picUrls.value)
}
const star = ref()
const textarea = ref("")


const showSign = ref(false)
const openSign = async () => {
  showSign.value = true;
  childActivityList.value = []
  //查询子活动列表
  let response = await getActivityListNotPage({mainId: id, isMain: "1", checkStatus: "1"});
  childActivityList.value = response.data
}
const showClaim = ref(false)
const showComment = ref(false)
const showSignUp = ref(false)
const name = ref('')
const company = ref('')
const sex = ref('男')
const station = ref('')
const team = ref('')


const submitClaim = async () => {
  let response = await claim({aid: id});
  if (response) {
    ElMessage({
      message: '认领成功',
      type: "success"
    })
  }
  showClaim.value = false
}
const handleCloseClaim = () => {
  showClaim.value = false
}
//todo: 支付
const checked = ref('1')
const cartList = ref([
  {id: "1", name: "微信支付", active: true},
  {id: "2", name: "余额支付", active: false},
  {id: "3", name: "支付宝支付", active: false}
])
const qrCodeUrl = ref('')
const orderId = ref('')
const payDialogVisible = ref(false)
const showPayCheck = ref(false)
const chooseItem = (index) => {
  cartList.value[index].active = true
  checked.value=cartList.value[index].id
  console.log("index===", cartList.value[index].active)
  for (let i in cartList.value) {
    if (i != index) {
      cartList.value[i].active = false
    } else {
      cartList.value[i].active = true
    }
  }
}
const submitSign = async () => {
  let data = {
    company: company.value,
    aid : id
  };

  //判断支付方式

  //1.不扣钱，不和积分挂钩
  if (activity.value.money == 0 && activity.value.score == 0) {
    let response = await signup(data);
    if (response) {
      showSign.value = false
    }
  } else if (activity.value.money == 0 && activity.value.score != 0) {
    //2.不扣钱，和积分挂钩
    let response = await signup(data);
    if (response) {
      showSign.value = false
    }
  } else if (activity.value.money != 0 && activity.value.score == 0) {
    //3.扣钱,不和积分挂钩
    showSign.value = false
    showPayCheck.value = true
  } else {
    showSign.value = false
    showPayCheck.value = true
  }
}
var timer = null
var socket;
const confirmPay = async () => {

  let payResult = await signup({
    aid: id,
    company: company.value,
    payType: checked.value,
    payWay: 'NATIVE'
  });
  if (payResult?.code === 200) {
    console.log("payresult==", payResult);
    payDialogVisible.value = true;
    qrCodeUrl.value = payResult.data.codeUrl
    orderId.value = payResult.data.orderId
  }
  const userId = getUserId()
  let startTime=10;
  function keepalive () {
    if(startTime===10){
      startTime=new Date().getTime()
    }
    // 超过1分钟则停止轮询
    if ((new Date().getTime() - startTime )> 60 * 1000) {
      ElMessage({
        message: '支付时间超过一分钟，请刷新！',
        type:"error"
      })
      orderId.value=''
      clearInterval(timer)
      socket.close()
      return
    }
    var chatMsg = {}
    chatMsg.type = 4
    //支付类型
    chatMsg.status = "2"
    chatMsg.orderId = orderId.value
    chatMsg.senderId = userId
    socket.send(JSON.stringify(chatMsg))
    console.log("发送心跳消息")
  }
  function onConnect () {
    timer = setInterval(function () {
      keepalive()
    }, 10000)
  }
  if(orderId.value!=null&&orderId.value!==""){
    socket=new WebSocket(socketUrl)

    //打开事件
    socket.onopen = function() {
      onConnect()
    }

    socket.onmessage = function (e) {
      //alert(e.data)
      console.log('eeee========', e)
      const datas = JSON.parse(e.data)
      if(datas.tradeState=='1'){
        ElMessage({
          message: '支付成功',
          type:"success"
        })
        orderId.value=''
        clearInterval(timer)
        socket.close()
        payDialogVisible.value = false
      }
    }
  }


}
const closePayDialog = () => {
  ElMessageBox.confirm('确定退出支付吗?')
      .then(() => {
        //还要关闭socket和轮询
        orderId.value=''
        clearInterval(timer)
        socket.close()
        payDialogVisible.value = false
      })
      .catch(() => {
        // catch error
      })
}
const submitComment = () => {
  setTimeout(async () => {
    let data = {
      aid: id,
      star: star.value,
      score: flower.value,
      picUrls: picUrls.value,
      content: textarea.value,
    }
    let response = await addComment(data);
    if (response) {
      showComment.value = false
    }
    await onLoad()
  })

}
const handleCloseSign = () => {
  ElMessageBox.confirm('确定关闭弹窗吗?')
      .then(() => {
        showSign.value = false
      })
      .catch(() => {
        // catch error
      })
}
const handleCloseComment = () => {
  showComment.value = false
}
const handleCloseSignUp = () => {
  showSignUp.value = false
}
const data = ref({});
const confirmSignUp = (val) => {
  if (val == false) {
    ElMessage({
      message: '签到中...',
      type: "message"
    })
    setTimeout(async () => {
      let response = await sign({aid: id});
      data.value = response.data
      if (response) {
        showSignUp.value = true;
      }
    }, 2000)
  } else {
    ElMessage({
      message: '签退中...',
      type: "message"
    })
    setTimeout(async () => {
      let response = await signoff({aid: id});
      data.value = response.data
      if (response) {
        showSignUp.value = true;
      }
    }, 2000)
  }

}

</script>

<style lang="scss" scoped>
.paydialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 90px;

  &_line {
    width: 100%;
    height: 1px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(245, 245, 245, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &_type {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_item {
      position: relative;
      margin-bottom: 30px;
      width: 350px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      font-size: 14px;
      text-align: center;
      padding: 30px 20px 30px 20px;
      border: 1px solid rgba(189, 189, 189, 100);
      color: rgba(16, 16, 16, 100);

      img {
        height: 30px;
      }
    }

    &_item--checked {
      position: relative;
      margin-bottom: 30px;
      width: 350px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      font-size: 14px;
      text-align: center;
      padding: 30px 20px 30px 20px;
      border: 1px solid rgba(255, 153, 89, 100);
      color: rgba(16, 16, 16, 100);

      img {
        height: 30px;
      }
    }
  }

  &_recharge {
    color: #212121;
    font-size: 20px;
    margin-bottom: 30px;

    &_money {
      margin-left: 5px;
      color: #ff9959;
    }
  }

  &_qrcode {
    margin-top: 30px;
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_btn {
    margin-top: 70px;
    background: #52cc6f;
    padding: 25px 15px 25px 15px;
    border-radius: 6px;
    color: white;
    font-size: 20px;
  }

  &_btn img {
    height: 30px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

::v-deep .el-textarea__inner {
  background-color: #F4F4F4;
}

::v-deep .el-input__wrapper {
  /*background-color: rgba(244, 244, 244, 100);*/
  height: 50px !important;
}

.el-select {
  width: 300px;
  line-height: 50px;
  border-radius: 2px;
  text-align: center;
}

.dialogS {
  padding: 30px 30px 50px 30px;

  .dialogSignUp {
    padding: 0 30px 0 30px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_sign {
      width: 100%;
      line-height: 20px;
      color: rgba(80, 85, 89, 100);
      font-size: 20px;
      text-align: center;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }

    &_line {
      width: 100%;
      height: 1px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(245, 245, 245, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      font-family: Roboto;
      margin-bottom: 30px;
      margin-top: 30px;
    }


    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }

    &_success {
      margin-top: 20px;
      line-height: 30px;
      color: rgba(33, 33, 33, 100);
      font-size: 20px;
      text-align: center;
      font-family: PingFangSC-medium;
      font-weight: bolder;
    }

    &_prefix {
      margin-top: 20px;
      line-height: 20px;
      color: rgba(33, 33, 33, 100);
      font-size: 20px;
      text-align: center;
      font-family: PingFangSC-medium;
    }

    &_name {
      margin-top: 10px;
      line-height: 20px;
      color: rgba(33, 33, 33, 100);
      font-size: 20px;
      text-align: center;
      font-family: PingFangSC-medium;
      font-weight: bolder;
    }

    &_address {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      line-height: 16px;
      font-size: 16px;

      &_name {
        margin-left: 280px;
        color: rgba(158, 158, 158, 100);
        text-align: left;
      }

      &_des {
        margin-left: 10px;
        color: rgba(66, 66, 66, 100);
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}


.dialog {
  padding: 30px 30px 50px 30px;

  &_user {
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    &_info {
      width: 100%;
      line-height: 20px;
      color: rgba(80, 85, 89, 100);
      font-size: 20px;
      text-align: left;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
      margin-left: 15px;
    }

    &_line {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100%;
      height: 1px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(245, 245, 245, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      font-family: Roboto;
    }

    &_star {
      width: 100%;
      height: 30px;
      text-align: left;

      .el-rate {
      }

      ::v-deep .el-rate__icon {
        font-size: 30px
      }

      ::v-deep .el-rate__text {
        font-size: 18px;
        font-weight: bolder;
      }
    }

    &_upload {
      width: 100%;
      text-align: left;

      ::v-deep .el-upload-list__item {
        height: 240px;
        width: 240px;
      }

      ::v-deep .el-upload--picture-card {
        height: 240px;
        width: 240px;
      }
    }

    &_re {
      width: 100%;
      display: flex;
      align-items: center;

      &_info {
        flex: 1;
        line-height: 20px;
        color: rgba(80, 85, 89, 100);
        font-size: 20px;
        text-align: left;
        font-family: PingFangSC-semiBold;
        font-weight: bolder;
        margin-left: 15px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }

      &_des {
        line-height: 14px;
        color: rgba(144, 147, 153, 100);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_stepper {

      height: 100px;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 20px;
      border-radius: 50px;
      background-color: rgba(245, 245, 245, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      font-family: Roboto;

      &_del {
        img {
          width: 60px;
          height: 60px;
        }
      }

      &_num {
        line-height: 60px;
        color: rgba(0, 0, 0, 1);
        font-size: 36px;
        text-align: center;
        font-family: PingFangSC-semiBold;
      }

      &_add {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }

    &_allFlower {
      margin-top: 20px;
      line-height: 16px;
      color: rgba(117, 117, 117, 100);
      font-size: 16px;
      text-align: left;
      font-family: PingFangSC-regular
    }

    &_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_item1 {
        display: flex;
        align-items: center;
        width: 30%;
        justify-content: space-between;

        &_left {
          width: 45%;

          &_name {
            margin-left: 15px;
            margin-bottom: 10px;
            line-height: 16px;
            color: rgba(132, 146, 166, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }

          .el-input {
            height: 50px;
          }
        }

        &_right {
          width: 45%;

          &_name {
            margin-left: 15px;
            margin-bottom: 10px;
            line-height: 16px;
            color: rgba(132, 146, 166, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }

          .el-input {
            height: 50px;
          }
        }
      }

      &_item {
        width: 30%;

        &_name {
          margin-bottom: 10px;
          margin-left: 15px;
          line-height: 16px;
          color: rgba(132, 146, 166, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .el-input {
          height: 50px;
        }
      }
    }
  }
}

.container {
  padding: 40px 30px 100px 30px;
  background-color: #F5F5F5;

  &_left {
    background-color: #FFFFFF;

    img {
      width: 100%;
      height: 220px;
    }

    &_content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &_title {
        width: 90%;
        line-height: 30px;
        height: 60px;
        color: rgba(33, 33, 33, 100);
        font-size: 20px;
        text-align: justify;
        font-family: HarmonyOS_Sans_SC-medium;
        font-weight: bolder;
      }

      /*报名*/
      &_sign {
        margin-top: 15px;
        width: 83%;
        line-height: 20px;
        border-radius: 6px;
        background-color: rgba(245, 245, 245, 100);
        color: rgba(16, 16, 16, 100);
        text-align: center;
        font-family: Roboto;
        display: flex;
        align-items: center;
        padding: 15px;

        &_status0 {
          padding: 6px;
          border-radius: 2px;
          background-color: rgba(33, 33, 33, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          line-height: 12px;
          font-family: PingFangSC-medium;
        }

        &_status1 {
          padding: 6px;
          border-radius: 2px;
          background-color: rgba(0, 230, 118, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          line-height: 12px;
          font-family: PingFangSC-medium;
        }

        &_status2 {
          padding: 6px;
          border-radius: 2px;
          background-color: rgba(98, 212, 204, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          line-height: 12px;
          font-family: PingFangSC-medium;
        }

        &_status3 {
          padding: 6px;
          border-radius: 2px;
          background-color: rgba(255, 153, 89, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          line-height: 12px;
          font-family: PingFangSC-medium;
        }

        &_num {
          flex: 1;
          line-height: 16px;
          color: rgba(158, 158, 158, 100);
          font-size: 16px;
          text-align: right;
          font-family: PingFangSC-regular;
        }
      }

      /*认领*/
      &_claim {
        margin-top: 15px;
        width: 83%;
        line-height: 20px;
        border-radius: 6px;
        background-color: rgba(245, 245, 245, 100);
        color: rgba(16, 16, 16, 100);
        text-align: center;
        font-family: Roboto;
        display: flex;
        align-items: center;
        padding: 15px;

        &_status {
          padding: 6px;
          border-radius: 2px;
          background-color: rgba(33, 33, 33, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          line-height: 12px;
          font-family: PingFangSC-medium;
        }

        &_num {
          flex: 1;
          line-height: 16px;
          color: rgba(158, 158, 158, 100);
          font-size: 16px;
          text-align: right;
          font-family: PingFangSC-regular;
          font-weight: bolder;
        }
      }

      /*评论*/
      &_comment {
        margin-top: 15px;
        width: 83%;
        line-height: 20px;
        border-radius: 6px;
        background-color: rgba(245, 245, 245, 100);
        color: rgba(16, 16, 16, 100);
        text-align: center;
        font-family: Roboto;
        display: flex;
        align-items: center;
        padding: 15px;

        &_status {
          padding: 6px;
          border-radius: 2px;
          background-color: rgba(33, 33, 33, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          line-height: 12px;
          font-family: PingFangSC-medium;
        }

        &_num {
          flex: 1;
          line-height: 16px;
          color: rgba(158, 158, 158, 100);
          font-size: 16px;
          text-align: right;
          font-family: PingFangSC-regular;
          font-weight: bolder;
        }
      }

      &_tags {
        width: 90%;
        margin-top: 20px;
        display: flex;
        align-items: center;

        &_actype {
          padding: 6px;
          margin-right: 10px;
          border-radius: 2px;
          background-color: rgba(255, 239, 229, 100);
          text-align: center;
          color: rgba(255, 98, 0, 100);
          font-size: 14px;
          font-family: PingFangSC-regular;
        }
      }

      &_line {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        height: 1px;
        line-height: 20px;
        border-radius: 6px;
        background-color: rgba(245, 245, 245, 100);
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        font-family: Roboto;
      }

      &_community {
        display: flex;
        align-items: center;
        width: 90%;

        img {
          width: 60px;
          height: 60px;
          border-radius: 25px;
        }

        &_name {
          margin-left: 20px;
          line-height: 20px;
          color: rgba(51, 51, 51, 100);
          font-size: 20px;
          text-align: left;
          font-family: PingFangSC-medium;
          font-weight: bolder;
        }

        &_des {
          flex: 1;
          line-height: 20px;
          color: rgba(193, 205, 209, 100);
          font-size: 16px;
          text-align: right;
          font-family: PingFangSC-regular;
        }
      }

      &_user {
        margin-top: 20px;
        width: 83%;
        display: flex;
        align-items: center;
        line-height: 20px;
        border-radius: 6px;
        background-color: rgba(245, 245, 245, 100);
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        font-family: Roboto;
        padding: 15px;

        &_name {
          flex: 1;
          color: rgba(51, 51, 51, 100);
          text-align: left;
          font-family: PingFangSC-regular;
        }

        img {
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }

        &_phone {
          color: rgba(51, 51, 51, 100);
          font-family: PingFangSC-regular;
        }
      }

      &_info {
        display: flex;
        align-items: center;
        width: 90%;
        margin-bottom: 30px;

        img {
          width: 50px;
          height: 50px;
        }

        &_user {
          margin-left: 30px;
          line-height: 20px;
          color: rgba(185, 190, 204, 100);
          font-size: 14px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
      }
    }
  }

  &_right {
    &_back {
      background-color: #FFFFFF;
      display: flex;
      align-items: center;

      &_left {
        height: 60px;
        width: 380px;
        background: url("../../assets/images/activity/back.png") 0 0 no-repeat;
        background-size: 100% 100%;
        flex: 1;
        display: flex;
        align-items: center;

        img {
          margin-left: 30px;
          width: 20px;
          height: 20px;

        }

        &_address {
          margin-left: 5px;
          line-height: 20px;
          color: rgba(255, 145, 0, 100);
          font-size: 18px;
          text-align: left;
          font-family: PingFangSC-semiBold;
          font-weight: bolder;
        }
      }

      &_right {
        flex: 1;
      }

      .el-button {
        height: 100%;
        padding: 20px 64px 20px 64px;
        color: rgba(59, 47, 0, 100);
        font-size: 18px;
        text-align: right;
        font-family: PingFangSC-semiBold;
      }
    }

    &_content {
      margin-top: 30px;
      background-color: #FFFFFF;
      padding: 30px 30px 80px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &_time {
        width: 100%;
        display: flex;
        align-items: center;

        &_activity {
          margin-right: 30px;
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }

          &_des {
            line-height: 20px;
            color: rgba(33, 33, 33, 100);
            font-size: 18px;
            text-align: left;
            font-family: PingFangSC-semiBold;
            font-weight: bolder;
          }
        }
      }

      &_line {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        height: 1px;
        line-height: 20px;
        border-radius: 6px;
        background-color: rgba(245, 245, 245, 100);
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: center;
        font-family: Roboto;
      }

      &_introduce {
        width: 100%;
        line-height: 50px;
        color: rgba(33, 33, 33, 100);
        font-size: 18px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }

    &_comment {
      margin-top: 30px;
      background-color: #FFFFFF;
      padding: 30px 30px 80px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &_top {
        display: flex;
        align-items: center;
        width: 100%;

        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        &_text {
          line-height: 20px;
          color: rgba(33, 33, 33, 100);
          font-size: 18px;
          text-align: left;
          font-family: PingFangSC-semiBold;
          font-weight: bolder;
        }
      }

      &_item {
        width: 100%;

        &_line {
          margin-top: 30px;
          margin-bottom: 30px;
          width: 100%;
          height: 1px;
          line-height: 20px;
          border-radius: 6px;
          background-color: rgba(245, 245, 245, 100);
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
          text-align: center;
          font-family: Roboto;
        }

        &_user {
          width: 100%;
          display: flex;
          align-items: center;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 30px;
          }

          &_name {
            display: flex;
            flex-direction: column;
            align-items: center;

            &_top {
              line-height: 24px;
              color: rgba(16, 16, 16, 100);
              font-size: 16px;
              text-align: left;
              font-family: SourceHanSansSC-regular;
            }

            &_bottom {
              margin-top: 5px;
              line-height: 12px;
              border-radius: 2px;
              background-color: rgba(33, 33, 33, 100);
              text-align: center;
              padding: 3px;
              color: rgba(255, 234, 0, 100);
              font-size: 12px;
              font-family: PingFangSC-semiBold;
            }
          }

          &_time {
            flex: 1;
            line-height: 24px;
            opacity: 0.38;
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            text-align: right;
            font-family: PingFangSC-medium;
          }
        }

        &_star {
          margin-top: 30px;
          width: 100%;
          text-align: left;

          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
        }

        &_content {
          margin-top: 20px;
          width: 100%;
          line-height: 30px;
          color: rgba(16, 16, 16, 100);
          font-size: 16px;
          text-align: justify;
          font-family: SourceHanSansSC-regular;
        }

        &_images {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          img {
            width: 420px;
            height: 240px;
            margin-right: 15px;
          }
        }

        &_bottom {
          margin-top: 20px;
          width: 100%;
          height: 36px;
          line-height: 20px;
          border-radius: 2px;
          background-color: rgba(250, 250, 250, 100);
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
          text-align: center;
          font-family: Roboto;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
            margin-left: 10px;
          }

          &_des {
            margin-left: 10px;
            line-height: 16px;
            color: rgba(80, 85, 89, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }

      &_page {

        margin-top: 50px;

        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
          /*  background-color:  rgba(240, 242, 245, 100);   // 进行修改未选中背景和字体
            color: #101010 ;
            //border: 1px solid #4581ae;
            border-radius: 5px;*/
          width: 60px;
          height: 60px;
          margin: 10px;
          line-height: 60px;
        }

        /*  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
            background-color: rgba(255, 153, 89, 100);
            color: #FFFFFF;                      //修改选中后的样式
            //border: 1px solid #4581ae;
            border-radius: 5px;
            width: 60px;
            height: 60px;
            margin: 10px;
            line-height: 60px;
          }*/
        //上一页和下一页的样式
        ::v-deep .el-pagination.is-background .btn-next {
          //margin: 0 5px;
          background-color: rgba(240, 242, 245, 100);
          color: #C0C4CC;
          min-width: 60px;
          height: 60px;
          border-radius: 5px;
          //border:1px solid #4581ae ;
        }

        ::v-deep .el-pagination.is-background .btn-prev {
          //margin: 0 5px;
          background-color: rgba(240, 242, 245, 100);
          color: #C0C4CC;
          min-width: 60px;
          height: 60px;
          border-radius: 5px;
          //border:1px solid #4581ae ;
        }

      }
    }
  }
}
</style>
